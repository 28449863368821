import { useMutation } from "@apollo/client";
import { Plus } from "@ignite-analytics/icons";
import { Button, Stack } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { ACCEPTED_FILE_EXTENSIONS, getContentType, getFileExtension, MAX_FILE_SIZE } from "@/lib/files";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";
import { usePolling } from "@/providers/PollingContext";

type Props = {
    isEditor: boolean;
    supplierId: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    color?: string;
};

export const Documents_CreateUploadURL = graphql(`
    mutation Documents_CreateUploadURL($input: CreateSupplierFileUploadUrlInput!) {
        createSupplierFileUploadUrl(input: $input) {
            url
        }
    }
`);

export const UploadButton: React.FC<Props> = ({ isEditor, supplierId, setIsLoading, refetch }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [uploadFile] = useMutation(Documents_CreateUploadURL);

    const { handleStartPolling } = usePolling();

    const handleUploadFiles = async (selectedFiles: FileList | null) => {
        if (!selectedFiles) return;
        setIsLoading(true);

        const blockedFiles: string[] = [];
        const selectedFilesInfo = Array.from(selectedFiles).reduce(
            (acc: { name: string; file: File }[], selectedFile) => {
                const fileName = selectedFile.name;
                if (selectedFile.size > MAX_FILE_SIZE) {
                    blockedFiles.push(fileName);
                } else {
                    acc.push({ name: fileName, file: selectedFile });
                }
                return acc;
            },
            []
        );

        if (blockedFiles.length > 0) {
            const blockedFilesList = blockedFiles.join(", ");
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{files} could not be uploaded because of file size (larger than 20MB).",
                        description: "Error uploading file",
                    },
                    { files: blockedFilesList }
                ),
                severity: "error",
            });
            track("Supplier Profile: Attempted to Upload Too Large Document", { supplierId });
        }

        if (selectedFilesInfo.length < 1) {
            setIsLoading(false);
            return;
        }

        const uploadPromises = selectedFilesInfo.map(async (selectedFileInfo) => {
            // create signed upload url
            const data = await uploadFile({
                variables: {
                    input: {
                        supplierId: supplierId,
                        fileName: selectedFileInfo.name,
                        fileMetaJson: JSON.stringify({
                            origin: "supplier-page",
                        }),
                    },
                },
                onError: () => {
                    alertUser({
                        value: formatMessage(
                            {
                                defaultMessage: "Error uploading file {file}",
                                description: "Error uploading file",
                            },
                            { file: selectedFileInfo.name }
                        ),
                        severity: "error",
                    });
                },
            });

            const url = data.data?.createSupplierFileUploadUrl.url;
            if (!url) return false;

            const fileExtension = getFileExtension(selectedFileInfo.name);
            if (!fileExtension) return false;

            const headers: { [key: string]: string } = {
                "x-goog-meta-origin": "supplier-page",
                "x-goog-content-length-range": "0,20971520",
                "Content-Type": getContentType(fileExtension),
            };

            const re = new RegExp(`[&/\\#,+()$~%'":*?<>{}]`, "g");
            const sanitizedFileName = selectedFileInfo.name.replace(re, "_");
            const encodedFileName = encodeURIComponent(sanitizedFileName);
            headers["x-goog-meta-av_scanned"] = "FALSE";
            headers["x-goog-meta-originalfilename"] = encodedFileName;
            headers["Content-Disposition"] = `attachment; filename*=UTF-8''${encodedFileName}`;

            // upload the file to the signed url
            return fetch(url, {
                method: "PUT",
                headers: headers,
                body: selectedFileInfo.file,
            }).catch(() => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "Error uploading file {file}",
                            description: "Error uploading file",
                        },
                        { file: selectedFileInfo.name }
                    ),
                    severity: "error",
                });
            });
        });
        await Promise.all(uploadPromises).finally(() => {
            refetch();
            handleStartPolling(3000, 21000);
            setIsLoading(false);
            track("Supplier Profile: Uploaded Document", { supplierId });
        });
    };

    return (
        <Stack width="140px">
            <NoPermissionTooltip
                hasPermission={isEditor}
                placement="top"
                message={<FormattedMessage defaultMessage="Your permissions do not allow adding a document" />}
            >
                <Button
                    component="label"
                    disabled={!isEditor}
                    startIcon={<Plus />}
                    size="small"
                    color="linkPrimary"
                    sx={{ justifyContent: "flex-start" }}
                >
                    <FormattedMessage defaultMessage="Add document" description="Add document button" />
                    <input
                        type="file"
                        multiple
                        hidden
                        accept={ACCEPTED_FILE_EXTENSIONS}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUploadFiles(e.target.files);
                        }}
                        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                            // Have to reset the value of the input to allow uploading the same file twice
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            e.target.value = null;
                        }}
                    />
                </Button>
            </NoPermissionTooltip>
        </Stack>
    );
};
