import { ArrowDown, ArrowUp } from "@ignite-analytics/icons";
import { Chip, Grid, IconButton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormatDateOptions, FormatNumberOptions, FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { InfoRow_SupplierCustomFieldFragment, OnboardingStatus, SupplierCustomFieldType } from "@/gql/graphql";

import { CountryOption } from "./countryCode";
import { EditField } from "./EditField/index";
import { getNACELabel } from "./NaceField/helpers";
import { NACEOptions } from "./NaceField/NACEOptions";

graphql(`
    fragment InfoRow_SupplierCustomField on SupplierCustomField {
        name
        dataJson
        fieldType
    }
`);

interface InfoRowProps {
    id: string;
    name: string;
    value: string | string[] | number; // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding";
    customField?: InfoRow_SupplierCustomFieldFragment;
    mode?: "edit" | "configure" | "display";
    tooltip?: string;
    onFieldChange?: (id: string, value: string) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onToggle?: (id: string, value: boolean) => void;
    checked?: boolean;
    selectOptions?: string[];
    component?: React.ReactNode;
    isFirst?: boolean;
    isLast?: boolean;
}

export interface ClassificationCustomField extends InfoRow_SupplierCustomFieldFragment {
    valueById: Map<string, string>;
}

// 1. Create a ValueFormatter component for just value display
const ValueFormatter: React.FC<{
    value: string | string[] | number;
    type: SupplierCustomFieldType | "onboarding";
}> = ({ value, type }) => {
    const { formatDate, formatNumber } = useIntl();
    return formatValue(value, type, formatDate, formatNumber);
};

// 2. Create a simple Field component for layout
const Field: React.FC<{
    fieldId: string;
    label: string;
    children: React.ReactNode;
    configurable?: boolean;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onToggle?: (id: string, value: boolean) => void;
    checked?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
}> = ({ fieldId, label, children, configurable, onMoveUp, onMoveDown, onToggle, checked, isFirst, isLast }) => (
    <Grid container alignItems="center" spacing={1} padding={1}>
        {configurable && (
            <Grid item xs={2}>
                {checked && (
                    <Stack direction="row" spacing={0.5}>
                        <IconButton
                            size="small"
                            onClick={onMoveUp}
                            disabled={isFirst}
                            sx={{ border: 1, borderColor: "divider" }}
                        >
                            <ArrowUp fontSize="inherit" />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={onMoveDown}
                            disabled={isLast}
                            sx={{ border: 1, borderColor: "divider" }}
                        >
                            <ArrowDown fontSize="inherit" />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
        )}
        <Grid item xs={configurable ? 4 : 6} textOverflow="ellipsis" overflow="clip" fontWeight={600}>
            <Typography variant="textSm">{label}</Typography>
        </Grid>
        <Grid item xs={configurable ? 5 : 6} sx={{ overflowX: "hidden", alignItems: "center" }}>
            {children}
        </Grid>
        {configurable && onToggle && (
            <Grid item xs={1}>
                <Switch checked={checked} onChange={(v) => onToggle(fieldId, v.target.checked)} />
            </Grid>
        )}
    </Grid>
);

// 3. Simplified InfoRow that composes these components
export const InfoRow: React.FC<InfoRowProps> = ({
    id,
    name,
    value,
    type,
    mode,
    tooltip,
    onFieldChange,
    onMoveUp,
    onMoveDown,
    onToggle,
    selectOptions,
    component,
    checked,
    isFirst,
    isLast,
}) => {
    if (component) {
        return (
            <Field fieldId={id} label={name}>
                {component}
            </Field>
        );
    }

    if (mode === "edit" && canEditType(type) && onFieldChange) {
        return (
            <Field fieldId={id} label={name}>
                <EditField
                    id={id}
                    value={value}
                    type={type}
                    onFieldChange={onFieldChange}
                    selectOptions={selectOptions}
                />
            </Field>
        );
    }

    const formattedValue = <ValueFormatter value={value} type={type} />;

    return (
        <Field
            fieldId={id}
            label={name}
            configurable={mode === "configure"}
            onToggle={onToggle}
            checked={checked}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            isFirst={isFirst}
            isLast={isLast}
        >
            {tooltip ? <Tooltip title={tooltip}>{formattedValue}</Tooltip> : formattedValue}
        </Field>
    );
};

function formatValue(
    value: string | string[] | number, // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding",
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string,
    numberFormatter: (value: Parameters<Intl.NumberFormat["format"]>[0], opts?: FormatNumberOptions) => string
): React.ReactElement {
    if (value === "" || value == null || value === undefined) {
        return <React.Fragment />;
    }

    if (type === SupplierCustomFieldType.Risk) {
        switch (value) {
            case "LOW":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Low" description="'Low' risk chip label" />}
                        color="success"
                        size="small"
                        variant="status"
                    />
                );
            case "VERY_LOW":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Very Low" description="'Very Low' risk chip label" />}
                        color="success"
                        size="small"
                        variant="status"
                    />
                );
            case "MEDIUM":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Medium" description="'Medium' risk chip label" />}
                        color="warning"
                        size="small"
                        variant="status"
                    />
                );
            case "HIGH":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="High" description="'High' risk chip label" />}
                        color="error"
                        size="small"
                        variant="status"
                    />
                );
            case "VERY_HIGH":
                return (
                    <Chip
                        label={
                            <FormattedMessage defaultMessage="Very High" description="'Very High' risk chip label" />
                        }
                        color="error"
                        size="small"
                        variant="status"
                    />
                );
        }
    }
    if (typeof value === "string") {
        switch (type) {
            case "onboarding": {
                return getOnboardingChip(value as OnboardingStatus);
            }
            case SupplierCustomFieldType.Date: {
                return <Typography variant="textSm">{formatDate(value, dateFormatter)}</Typography>;
            }
            case SupplierCustomFieldType.Nace: {
                const option = NACEOptions.find((opt) => opt.code === value.slice(0, 5)) ?? null;
                return <Typography variant="textSm">{`${option?.code}: ${getNACELabel(option)}`}</Typography>;
            }
        }
    }

    if (Array.isArray(value) && type === SupplierCustomFieldType.Classification) {
        return (
            <Stack direction="row" gap={1} flexWrap="wrap">
                {value.map((v: string, i: number) => {
                    return (
                        <Typography variant="textSm" key={`${v}_${i}`}>
                            {v}
                        </Typography>
                    );
                })}
            </Stack>
        );
    }

    if (typeof value === "number") {
        switch (type) {
            case SupplierCustomFieldType.Number:
            case SupplierCustomFieldType.MonetaryAmount:
            case SupplierCustomFieldType.Aggregation:
            case SupplierCustomFieldType.Spend:
                return <Typography variant="textSm">{numberFormatter(value, { maximumFractionDigits: 2 })}</Typography>;
        }
    }

    return <Typography variant="textSm">{value}</Typography>;
}

function getOnboardingChip(status: OnboardingStatus | undefined) {
    switch (status) {
        case OnboardingStatus.Approved:
            return <Chip color="success" label="Approved" size="small" variant="status" />;
        case OnboardingStatus.InOnboarding:
            return <Chip color="warning" label="In onboarding" size="small" variant="status" />;
        case OnboardingStatus.Rejected:
            return <Chip color="error" label="Rejected" size="small" variant="status" />;
        default:
            return <></>;
    }
}

// TODO: Have to figure out "empty state"/auto approved of onboarding
function formatDate(
    dateStr: string | null | undefined,
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string
): React.ReactNode | "" {
    // Check if the input is null or undefined
    if (dateStr === null || dateStr === undefined) {
        return "";
    }

    // Parse the date string
    const date = new Date(dateStr);

    // Create a date object for "0001-01-01 00:00:00 +0000 UTC"
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));

    // Check if the parsed date is invalid
    if (isNaN(date.getTime())) {
        return "";
    }

    // Compare the provided date with the invalid date
    if (date.getTime() === invalidDate.getTime()) {
        return "";
    }

    return dateFormatter(date);
}

export function isValidDate(dateString: string) {
    const date = new Date(dateString ?? "");
    if (!date || isNaN(date.getTime())) {
        return false;
    }
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));
    return date.getTime() !== invalidDate.getTime();
}

function canEditType(type: SupplierCustomFieldType | "onboarding") {
    switch (type) {
        case SupplierCustomFieldType.Text:
        case SupplierCustomFieldType.Number:
        case SupplierCustomFieldType.MonetaryAmount:
        case SupplierCustomFieldType.Date:
        case SupplierCustomFieldType.Nace:
        case SupplierCustomFieldType.Spend:
        case SupplierCustomFieldType.Boolean:
        case SupplierCustomFieldType.Select:
            return true;
        default:
            return false;
    }
}

export function getCountryLabel(option: CountryOption | undefined | null) {
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb") {
        return `${option.name_no}`;
    }
    return `${option.name_en}`;
}

export interface OrderedField {
    fieldId: string;
    order: number;
}

// Helper function to reorder fields
export function reorderFields(fields: OrderedField[], fieldId: string, direction: "up" | "down"): OrderedField[] {
    const currentIndex = fields.findIndex((f) => f.fieldId === fieldId);
    if (currentIndex === -1) return fields;

    const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= fields.length) return fields;

    const newFields = [...fields];
    // Swap the fields
    [newFields[currentIndex], newFields[newIndex]] = [newFields[newIndex], newFields[currentIndex]];
    // Update their order values
    newFields[currentIndex].order = currentIndex;
    newFields[newIndex].order = newIndex;

    return newFields;
}
