import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";

import { graphql } from "@/gql";
import { SideColumn_SupplierFragment } from "@/gql/graphql";

import { SupplierContacts } from "./SupplierContacts/SupplierContacts";
import { SupplierInfo } from "./SupplierInfo/SupplierInfo";

graphql(`
    fragment SideColumn_Supplier on Supplier {
        ...SupplierInfo_Supplier
        ...SupplierContacts_Supplier
    }
`);

const getSupplierProfileAboutConfigQuery = graphql(`
    query getSupplierProfileAboutConfig {
        getSupplierProfileAboutConfig {
            config {
                displayOnboarding
                displaySocialRisk
                displayedCustomFields
            }
        }
    }
`);
interface SideColumnProps {
    supplier: SideColumn_SupplierFragment;
}

export const SideColumn: React.FC<SideColumnProps> = ({ supplier }) => {
    const profileConfigResponse = useQuery(getSupplierProfileAboutConfigQuery);
    const profileConfig = profileConfigResponse.data?.getSupplierProfileAboutConfig.config;
    return (
        <Stack spacing={3}>
            {profileConfig && <SupplierInfo supplier={supplier} profileConfig={profileConfig} />}
            <SupplierContacts supplier={supplier} />
        </Stack>
    );
};
