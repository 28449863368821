import { useMutation } from "@apollo/client";
import { DotsHorizontal, Smile } from "@ignite-analytics/icons";
import { Avatar, ButtonGroup, Card, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { EmojiClickData } from "emoji-picker-react";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { Activity_NoteFragment, AppRoutes_GetSupplierDocument, UserFragment } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useUser } from "@/providers/UserContext";

import "dayjs/locale/en";
import "dayjs/locale/nb";
import { DeleteNoteModal } from "./DeleteNoteModal";
import { EditNote } from "./EditNote";
import { Emoji, EmojiPickerPopover, StyledEmoji } from "./Emoji";
import { Reaction } from "./types";
import { normalizeLocale } from "./utils";
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const MAX_DISPLAY_NOTE_LENGTH = 200;

const addNoteReactionDocument = graphql(`
    mutation Note_AddNoteReaction($input: addNoteReactionInput!) {
        addNoteReaction(input: $input) {
            note {
                ...Activity_Note
            }
        }
    }
`);

const removeNoteReactionDocument = graphql(`
    mutation Note_RemoveNoteReaction($input: removeNoteReactionInput!) {
        removeNoteReaction(input: $input) {
            note {
                ...Activity_Note
            }
        }
    }
`);

export const NoteActivity: React.FC<{
    note: Activity_NoteFragment;
    createdBy: UserFragment;
    isEditor: boolean;
    users: UserFragment[];
}> = ({ note, createdBy, isEditor, users }) => {
    let userDisplayName = "Unknown";
    let initials = "";

    const { locale, formatMessage } = useIntl();
    const browserTz = dayjs.tz.guess();

    dayjs.locale(normalizeLocale(locale));

    if (createdBy) {
        userDisplayName = `${createdBy.firstName} ${createdBy.lastName}`;
        initials = `${createdBy.firstName.charAt(0)}${createdBy.lastName.charAt(0)}`;
    }

    const currentUser = useUser();

    const [addNoteReaction] = useMutation(addNoteReactionDocument, {
        refetchQueries: [AppRoutes_GetSupplierDocument],
        update: (cache, { data }) => {
            const updatedNote = data?.addNoteReaction.note;
            if (updatedNote) {
                cache.modify({
                    id: cache.identify({ __typename: "Supplier", id: note.supplierId }),
                    fields: {
                        notes(existingNotes = []) {
                            return existingNotes.map((noteRef: { id: string }) => {
                                if (noteRef.id === updatedNote.id) {
                                    return updatedNote;
                                }
                                return noteRef;
                            });
                        },
                    },
                });
            }
        },
    });

    const [removeNoteReaction] = useMutation(removeNoteReactionDocument, {
        refetchQueries: [AppRoutes_GetSupplierDocument],
        update: (cache, { data }) => {
            const updatedNote = data?.removeNoteReaction?.note;
            if (updatedNote) {
                cache.modify({
                    id: cache.identify({ __typename: "Supplier", id: note.supplierId }),
                    fields: {
                        notes(existingNotes = []) {
                            return existingNotes.map((noteRef: Activity_NoteFragment) => {
                                if (noteRef.id === updatedNote.id) {
                                    return updatedNote;
                                }
                                return noteRef;
                            });
                        },
                    },
                });
            }
        },
    });

    const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState<HTMLElement | null>(null);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const [emojis, setEmojis] = useState<Emoji[]>([]);
    const [showOptions, setShowOptions] = useState(false);

    const [expanded, setExpanded] = useState(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [deleteNote, setDeleteNote] = useState<boolean>(false);
    const [noteActionsAnchorEl, setNoteActionsAnchorEl] = useState<null | HTMLElement>(null);

    const needsExpansion = note.note.length > MAX_DISPLAY_NOTE_LENGTH;
    const text = !needsExpansion || expanded ? note.note : `${note.note.slice(0, MAX_DISPLAY_NOTE_LENGTH)}...`;

    useEffect(
        () =>
            setEmojis(
                note.reactions?.map((reaction: Reaction) => {
                    return {
                        code: reaction.emojiCode,
                        count: reaction.userIDs.length,
                        actors: reaction.userIDs.map((id) => {
                            const user = users.find((user: UserFragment) => user.id === id);
                            const { firstName, lastName } = user ?? { firstName: "Unknown", lastName: "User" };
                            return {
                                id: id,
                                firstName: firstName,
                                lastName: lastName,
                            };
                        }),
                        clicked: reaction.userIDs.includes(currentUser.id),
                    };
                }) ?? []
            ),
        [createdBy.id, currentUser.id, note.createdBy, note.reactions, users]
    );

    function HandleEmojiInteraction(code: string, active: boolean) {
        if (active) {
            track("Supplier Profile: Removed Emoji Reaction", { emojiCode: code });
            removeNoteReaction({
                variables: {
                    input: {
                        noteId: note.id,
                        emojiCode: code,
                    },
                },
            });
        } else {
            track("Supplier Profile: Added Emoji Reaction", { emojiCode: code });
            addNoteReaction({
                variables: {
                    input: {
                        noteId: note.id,
                        emojiCode: code,
                    },
                },
            });
        }
    }

    function HandleEmojiPickerInteraction(emojiData: EmojiClickData) {
        track("Supplier Profile: Added Emoji Reaction From Picker", { emojiCode: emojiData.unified });
        addNoteReaction({
            variables: {
                input: {
                    noteId: note.id,
                    emojiCode: emojiData.unified,
                },
            },
        });
        setEmojiPickerOpen(false);
    }

    return (
        <div
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => {
                setShowOptions(false);
                setEmojiPickerOpen(false);
                setNoteActionsAnchorEl(null);
            }}
        >
            <Card sx={{ padding: 2 }}>
                <Grid item>
                    <Stack direction="row" alignItems="start" spacing={1}>
                        <Avatar sx={{ bgcolor: (theme) => theme.palette.grey[50], height: "32px", width: "32px" }}>
                            <Typography variant="textSm">{initials}</Typography>
                        </Avatar>
                        <Stack flexGrow={1} sx={{ minWidth: 0 }} spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="space-between"
                                justifyContent="space-between"
                                sx={{
                                    minWidth: 0,
                                }}
                                flexGrow={1}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="start"
                                    sx={{
                                        maxWidth: showOptions ? "calc(100% - 100px)" : "100%",
                                    }}
                                >
                                    <Typography
                                        variant="textSm"
                                        fontWeight={500}
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "100%",
                                        }}
                                    >
                                        {userDisplayName}
                                    </Typography>
                                    <Typography
                                        variant="textSm"
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "100%",
                                        }}
                                    >
                                        {note.updatedAt !== note.createdAt && (
                                            <Tooltip
                                                title={formatMessage(
                                                    {
                                                        defaultMessage: `{onboardingDate} ago`,
                                                    },
                                                    {
                                                        onboardingDate: dayjs
                                                            .utc(note.updatedAt)
                                                            .tz(browserTz)
                                                            .fromNow(true),
                                                    }
                                                )}
                                            >
                                                <Typography variant="textSm" color="text.text-helper">
                                                    <FormattedMessage defaultMessage="(edited)・" />
                                                </Typography>
                                            </Tooltip>
                                        )}
                                        {formatMessage(
                                            {
                                                defaultMessage: `{onboardingDate} ago`,
                                            },
                                            {
                                                onboardingDate: dayjs.utc(note.createdAt).tz(browserTz).fromNow(true),
                                            }
                                        )}
                                    </Typography>
                                </Stack>
                                {showOptions && (
                                    <ButtonGroup size="xsmall" sx={{ padding: 0 }}>
                                        <IconButton
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                track("Supplier Profile: Open Emoji Picker");
                                                setEmojiPickerAnchorEl(event.currentTarget);
                                                setEmojiPickerOpen(true);
                                            }}
                                        >
                                            <Smile />
                                        </IconButton>
                                        <Tooltip
                                            title={
                                                currentUser.id !== createdBy.id || !isEditor
                                                    ? "You do not have permission to edit this note"
                                                    : undefined
                                            }
                                            placement="top"
                                        >
                                            <IconButton
                                                disabled={currentUser.id !== createdBy.id || !isEditor}
                                                onClick={(event) => {
                                                    track("Supplier Profile: Open Note Actions");
                                                    setNoteActionsAnchorEl(event.currentTarget);
                                                }}
                                                sx={{
                                                    // This is a workaround to make the IconButton send PointerEvent when disabled
                                                    "&.Mui-disabled": {
                                                        pointerEvents: "auto",
                                                    },
                                                }}
                                            >
                                                <DotsHorizontal />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            anchorEl={noteActionsAnchorEl}
                                            open={Boolean(noteActionsAnchorEl)}
                                            onClose={() => setNoteActionsAnchorEl(null)}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    setEditing(true);
                                                    setNoteActionsAnchorEl(null);
                                                }}
                                            >
                                                <FormattedMessage defaultMessage="Edit" />
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    setDeleteNote(true);
                                                    setNoteActionsAnchorEl(null);
                                                }}
                                            >
                                                <FormattedMessage defaultMessage="Delete" />
                                            </MenuItem>
                                        </Menu>
                                    </ButtonGroup>
                                )}
                            </Stack>
                            {editing ? (
                                <EditNote
                                    oldNote={note}
                                    onClose={() => setEditing(false)}
                                    onUpdate={() => setEditing(false)}
                                />
                            ) : (
                                <>
                                    <Typography
                                        variant="textSm"
                                        sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                                    >
                                        {text}
                                        {needsExpansion && (
                                            <Typography
                                                variant="textSm"
                                                color="text.text-helper"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => setExpanded(!expanded)}
                                            >
                                                {expanded ? (
                                                    <FormattedMessage defaultMessage="Show less" />
                                                ) : (
                                                    <FormattedMessage defaultMessage="Show more" />
                                                )}
                                            </Typography>
                                        )}
                                    </Typography>
                                    <Stack gap={0.5} direction="row" flexWrap="wrap" maxWidth="200px">
                                        {emojis.map((emoji) => (
                                            <StyledEmoji
                                                key={emoji.code}
                                                {...emoji}
                                                onClick={() => HandleEmojiInteraction(emoji.code, emoji.clicked)}
                                                actors={emoji.actors}
                                                currentUser={currentUser.id}
                                                popover
                                            />
                                        ))}
                                    </Stack>
                                </>
                            )}
                            <EmojiPickerPopover
                                setEmojiPickerOpen={setEmojiPickerOpen}
                                setEmojis={setEmojis}
                                emojiPickerOpen={emojiPickerOpen}
                                emojiPickerAnchorEl={emojiPickerAnchorEl}
                                HandleEmojiPickerInteraction={HandleEmojiPickerInteraction}
                            />
                        </Stack>
                        {deleteNote && (
                            <DeleteNoteModal
                                onClose={() => setDeleteNote(false)}
                                note={note}
                                userDisplayName={userDisplayName}
                                initials={initials}
                            />
                        )}
                    </Stack>
                </Grid>
            </Card>
        </div>
    );
};
