import { Box, Stack, Typography } from "@mui/material";
import React from "react";

// Display percentage value inside the bar
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const displayPercentage = ({ x, y, width, value, height }: any) => {
    if (value === 0) {
        return null;
    }
    const formattedPercentage = `${(value * 100).toFixed(1)}%`;
    return (
        <Typography
            component="text"
            x={x + width / 2}
            y={y + height / 2}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            variant="textSm"
            style={{ userSelect: "none" }}
        >
            {formattedPercentage}
        </Typography>
    );
};

//Display standard legend
export const renderCustomLegend = (legendItems: { title: string; color?: string }[]) => (
    <Stack direction="row" display="flex" justifyContent="center" alignItems="center" width="100%">
        {legendItems.map((item, index) => (
            <React.Fragment key={index}>
                <Box width={10} height={10} mr={1} bgcolor={item.color} borderRadius="50%" />
                <Typography variant="textSm" color="textTextBody">
                    {item.title}
                </Typography>
                {index < legendItems.length - 1 && <Box marginLeft={8} />}
            </React.Fragment>
        ))}
    </Stack>
);

export const camelCaseToEnglish = (camelCaseString: string | null | undefined) => {
    if (!camelCaseString) {
        return "";
    }
    const result = camelCaseString.replace(/([A-Z])/g, " $1").toLowerCase();
    return result.charAt(0).toUpperCase() + result.slice(1);
};

// Localized formatting impl from carbon app
// Rounds to the nearest number with the specified number of decimal places
const roundToNumber = (num: number, decimalPlaces: number) => {
    const p = 10 ** decimalPlaces;
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
};

export const formatNumber = (num: number, decimalPlaces: number) => {
    return Intl.NumberFormat(undefined, { minimumFractionDigits: decimalPlaces }).format(
        roundToNumber(num, decimalPlaces)
    );
};
