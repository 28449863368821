export const GraphEmptyState: React.FC = () => {
    return (
        <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="8.5" width="40" height="40" rx="20" fill="#EFF8FE" />
            <rect x="1" y="1.5" width="54" height="54" rx="27" stroke="#38BCF7" strokeOpacity="0.16" strokeWidth="2" />
            <rect x="5" y="5.5" width="46" height="46" rx="23" stroke="#38BCF7" strokeOpacity="0.32" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5 21.835C21.4089 21.835 21.335 21.9089 21.335 22V25.8334C21.335 25.9245 21.4089 25.9984 21.5 25.9984H25.3334C25.4245 25.9984 25.4984 25.9245 25.4984 25.8334V22C25.4984 21.9089 25.4245 21.835 25.3334 21.835H21.5ZM19.665 22C19.665 20.9866 20.4866 20.165 21.5 20.165H25.3334C26.3468 20.165 27.1684 20.9866 27.1684 22V25.8334C27.1684 26.8468 26.3468 27.6684 25.3334 27.6684H21.5C20.4866 27.6684 19.665 26.8468 19.665 25.8334V22ZM28.8317 22C28.8317 20.9866 29.6533 20.165 30.6667 20.165H34.5C35.5135 20.165 36.335 20.9866 36.335 22V25.8334C36.335 26.8468 35.5135 27.6684 34.5 27.6684H30.6667C29.6533 27.6684 28.8317 26.8468 28.8317 25.8334V22ZM30.6667 21.835C30.5756 21.835 30.5017 21.9089 30.5017 22V25.8334C30.5017 25.9245 30.5756 25.9984 30.6667 25.9984H34.5C34.5912 25.9984 34.665 25.9245 34.665 25.8334V22C34.665 21.9089 34.5912 21.835 34.5 21.835H30.6667ZM19.665 31.1667C19.665 30.1533 20.4866 29.3317 21.5 29.3317H25.3334C26.3468 29.3317 27.1684 30.1533 27.1684 31.1667V35C27.1684 36.0135 26.3468 36.835 25.3334 36.835H21.5C20.4866 36.835 19.665 36.0135 19.665 35V31.1667ZM21.5 31.0017C21.4089 31.0017 21.335 31.0756 21.335 31.1667V35C21.335 35.0912 21.4089 35.165 21.5 35.165H25.3334C25.4245 35.165 25.4984 35.0912 25.4984 35V31.1667C25.4984 31.0756 25.4245 31.0017 25.3334 31.0017H21.5ZM32.5834 29.3317C33.0445 29.3317 33.4184 29.7055 33.4184 30.1667V32.2484H35.5C35.9612 32.2484 36.335 32.6222 36.335 33.0834C36.335 33.5445 35.9612 33.9184 35.5 33.9184H33.4184V36C33.4184 36.4612 33.0445 36.835 32.5834 36.835C32.1222 36.835 31.7484 36.4612 31.7484 36V33.9184H29.6667C29.2055 33.9184 28.8317 33.5445 28.8317 33.0834C28.8317 32.6222 29.2055 32.2484 29.6667 32.2484H31.7484V30.1667C31.7484 29.7055 32.1222 29.3317 32.5834 29.3317Z"
                fill="#069CDF"
            />
        </svg>
    );
};
