import { useMutation, useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { CircleSolid, X } from "@ignite-analytics/icons";
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import {
    AboutSupplierModal_ColumnsFragment,
    AboutSupplierModal_SupplierFragment,
    AboutSupplierModal_SupplierFragmentDoc,
    ClassificationOptions,
    ColumnType,
    SupplierCustomFieldType,
} from "@/gql/graphql";
import { useGeneralPermission, useSupplierPermission } from "@/lib/permissions";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";

import { IgniteEstimateChip, ManualStatusChip } from "../../../../Common/StatusChip";

import { CertsInfo } from "./CertsInfo";
import { GeoRisk } from "./RiskFields/GeoRisk";
import { IndustryRisk } from "./RiskFields/IndustryRisk";
import { ProductionCountries } from "./RiskFields/ProductionCountries";
import { InfoRow, OrderedField, reorderFields } from "./utils";

graphql(`
    fragment AboutSupplierModal_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        productionCountries
        socialRiskScore {
            value
        }
        hasCodeOfConduct
        customFields {
            name
            dataJson
            fieldType
            fieldId
        }
        risk {
            social
            industry
            geography
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
        codeOfConducts {
            filename
            expiresAt
            uploadedAt
        }
        certifications {
            filename
            expiresAt
            uploadedAt
            documentType
            uploadedBy
            originalFilename
        }
    }

    fragment AboutSupplierModal_Columns on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                }
            }
            ... on SelectOptions {
                choices
            }
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query getSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                type
                ...AboutSupplierModal_Columns
            }
        }
    }
`);

const updateSupplierFieldMutation = graphql(`
    mutation SupplierInfo_UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...AboutSupplierModal_Supplier
            }
        }
    }
`);

const getSupplierProfileAboutConfigQuery = graphql(`
    query getSupplierProfileAboutConfig {
        getSupplierProfileAboutConfig {
            config {
                displayOnboarding
                displaySocialRisk
                displayedCustomFields
            }
        }
    }
`);

const updateSupplierProfileConfigMutation = graphql(`
    mutation updateSupplierProfileConfig($input: UpdateSupplierProfileAboutConfigInput!) {
        updateSupplierProfileAboutConfig(input: $input) {
            config {
                displayOnboarding
                displaySocialRisk
                displayedCustomFields
            }
        }
    }
`);

interface AboutSupplierModalProps {
    mode: "edit" | "configure" | "display";
    supplier: AboutSupplierModal_SupplierFragment;
    approver?: string;
    setModalView: (v: "display" | "edit" | "configure" | null) => void;
    onboardingEnabled: boolean;
    profileConfig: {
        displayOnboarding: boolean;
        displaySocialRisk: boolean;
        displayedCustomFields: string[];
    };
}

export const AboutSupplierModal: React.FC<AboutSupplierModalProps> = ({
    mode,
    supplier,
    approver,
    setModalView,
    profileConfig,
    onboardingEnabled,
}) => {
    const isAdmin = useGeneralPermission({ object: "privileged", relation: "write" });
    const isEditor = useSupplierPermission({ object: "general", relation: "write" });
    const [displayOnboarding, setDisplayOnboarding] = useState(profileConfig.displayOnboarding);
    const [displaySocialRisk, setDisplaySocialRisk] = useState(profileConfig.displaySocialRisk);
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const newSocialRisk = useFeatureToggle("new-social-risk", false);
    const newUploadEnabled = useFeatureToggle("new-upload", false);
    const customFields = supplier.customFields.filter(
        (field) =>
            ![SupplierCustomFieldType.Assessment, SupplierCustomFieldType.Nace, SupplierCustomFieldType.Risk].includes(
                field.fieldType
            )
    );
    const [updateSupplierField] = useMutation(updateSupplierFieldMutation, {
        update: (cache, { data }) => {
            const updatedSupplier = data?.updateSupplierField?.supplier;
            if (!updatedSupplier) return;
            cache.writeFragment({
                id: `Supplier:${supplier.id}`,
                fragment: AboutSupplierModal_SupplierFragmentDoc,
                fragmentName: "AboutSupplierModal_Supplier",
                data: {
                    ...updatedSupplier,
                },
            });
        },
        onError: (error) => {
            alertUser({
                value: formatMessage({ defaultMessage: `Could not update supplier` }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to update supplier" },
            });
        },
    });

    const [updateProfileConfig] = useMutation(updateSupplierProfileConfigMutation, {
        update: (cache, { data }) => {
            const updatedConfig = data?.updateSupplierProfileAboutConfig.config;
            if (updatedConfig) {
                cache.writeQuery({
                    query: getSupplierProfileAboutConfigQuery,
                    data: {
                        __typename: "Query",
                        getSupplierProfileAboutConfig: {
                            __typename: "GetSupplierProfileAboutConfigResponse",
                            config: updatedConfig,
                        },
                    },
                });
            }
        },
    });
    const { data } = useQuery(getSupplierTableColumnsQuery);
    const columns = data?.getSupplierTableMeta.columns ?? [];
    const supplierUpdates: Record<string, string> = {};
    const selectOptions = columns
        .filter((c) => c.type == "SELECT")
        .reduce(
            (acc, c) => {
                if (c.typeOptions?.__typename === "SelectOptions") {
                    acc[c.id] = c.typeOptions.choices;
                }
                return acc;
            },
            {} as Record<string, string[]>
        );

    const onFieldChange = (fieldId: string, value: string) => {
        supplierUpdates[fieldId] = value;
    };

    const handleClose = () => {
        track("Supplier Profile: Show More Supplier Info Closed");
        setModalView(null);
    };

    const handleEditSave = async () => {
        setLoading(true);
        for (const [key, value] of Object.entries(supplierUpdates)) {
            await updateSupplierField({
                variables: {
                    input: {
                        id: supplier.id,
                        fieldId: key,
                        fieldData: JSON.stringify(value),
                    },
                },
            });
        }
        track("Supplier Profile: Save Supplier Info Clicked");
        setLoading(false);
    };

    const [orderedFields, setOrderedFields] = useState<OrderedField[]>(
        profileConfig.displayedCustomFields.map((fieldId, index) => ({
            fieldId,
            order: index,
        }))
    );

    const handleMoveField = (fieldId: string, direction: "up" | "down") => {
        setOrderedFields((prev) => reorderFields(prev, fieldId, direction));
    };

    const handleConfigureSave = async () => {
        setLoading(true);
        await updateProfileConfig({
            variables: {
                input: {
                    displayOnboarding,
                    displaySocialRisk,
                    displayedCustomFields: orderedFields.map((f) => f.fieldId),
                },
            },
        });
        track("Supplier Profile: Save Supplier About Configuration Clicked");
        setLoading(false);
    };

    const handleFieldCheck = (fieldId: string, value: boolean) => {
        if (value) {
            setOrderedFields([...orderedFields, { fieldId, order: orderedFields.length }]);
        } else {
            setOrderedFields(
                orderedFields.filter((f) => f.fieldId !== fieldId).map((f, index) => ({ ...f, order: index }))
            );
        }
    };

    const handleSave = () => {
        if (mode === "edit") {
            handleEditSave();
        } else if (mode === "configure") {
            handleConfigureSave();
        }
        setModalView(null);
    };

    return (
        <Dialog
            open={mode !== null}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={handleClose}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack rowGap={2}>
                    {mode === "configure" && (
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Configure which fields are shown in the supplier information section. Changes made here apply to all supplier profiles and for all users in your workspace."
                                description="Descriptive text for supplier profile configuration modal"
                            />
                        </Typography>
                    )}
                    {/* 
                        General information 
                        Fields in this section are editable, but not configurable.
                    */}
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12} alignItems="center" textOverflow="ellipsis" overflow="clip">
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="About" description="Supplier information header" />
                            </Typography>
                        </Grid>
                        <InfoRow
                            id="name"
                            name={formatMessage({ defaultMessage: "Name", description: "Name of the supplier" })}
                            value={supplier.name ?? ""}
                            type={SupplierCustomFieldType.Text}
                            onFieldChange={onFieldChange}
                            mode={mode === "edit" ? "edit" : "display"}
                        />
                        <InfoRow
                            id="country"
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                            onFieldChange={onFieldChange}
                            mode={mode === "edit" ? "edit" : "display"}
                        />

                        <InfoRow
                            id={columns.find((c) => c.type === ColumnType.Nace)?.id ?? ""}
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                            onFieldChange={onFieldChange}
                            mode={mode === "edit" ? "edit" : "display"}
                        />
                        <InfoRow
                            id="reg_nr"
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                            onFieldChange={onFieldChange}
                            mode={mode === "edit" ? "edit" : "display"}
                        />
                    </Grid>
                    <Divider />
                    {/* Onboarding */}
                    {supplier.onboarding && onboardingEnabled && (
                        <>
                            <Grid container>
                                <Grid item xs={11} alignItems="center" textOverflow="ellipsis" overflow="clip">
                                    <Typography variant="textMd" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Onboarding"
                                            description="Onboarding info header"
                                        />
                                    </Typography>
                                </Grid>
                                {mode === "configure" && (
                                    <Grid item xs={1}>
                                        <Switch
                                            checked={displayOnboarding}
                                            onChange={(v) => setDisplayOnboarding(v.target.checked)}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    id="onboarding-approver"
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={approver ?? ""}
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="onboarding-approved-at"
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={supplier.onboarding.evaluatedAt ?? ""}
                                    type={SupplierCustomFieldType.Date}
                                />
                                <InfoRow
                                    id="onboarding-status"
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                            <Divider />
                        </>
                    )}
                    <Grid container>
                        <Grid item xs={11} alignItems="center" textOverflow="ellipsis" overflow="clip">
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Social Risk" description="Social Risk info header" />
                            </Typography>
                        </Grid>
                        {mode === "configure" && (
                            <Grid item xs={1}>
                                <Switch
                                    checked={displaySocialRisk}
                                    onChange={(v) => setDisplaySocialRisk(v.target.checked)}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container rowSpacing={2}>
                        {newSocialRisk ? (
                            <InfoRow
                                id="social-risk"
                                name={formatMessage({ defaultMessage: "Estimated Risk", description: "Social risk" })}
                                value=""
                                type={SupplierCustomFieldType.Risk}
                                component={
                                    supplier.socialRiskScore?.value ? (
                                        <ManualStatusChip status={supplier.socialRiskScore.value} />
                                    ) : (
                                        <IgniteEstimateChip status={supplier.risk?.social} />
                                    )
                                }
                            />
                        ) : (
                            <InfoRow
                                id="social-risk"
                                name={formatMessage({ defaultMessage: "Social risk", description: "Social risk" })}
                                value={supplier.risk?.social ?? ""}
                                type={SupplierCustomFieldType.Risk}
                            />
                        )}
                        <InfoRow
                            id="geography-risk"
                            name={formatMessage({ defaultMessage: "Geography risk", description: "Geography risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={
                                <GeoRisk country={(supplier.productionCountries[0] || supplier.country) ?? ""} />
                            }
                        />
                        {newSocialRisk && (
                            <InfoRow
                                id="production-countries"
                                name={formatMessage({
                                    defaultMessage: "Production countries",
                                    description: "Production countries",
                                })}
                                value=""
                                type={SupplierCustomFieldType.Text}
                                component={<ProductionCountries countries={supplier.productionCountries ?? []} />}
                            />
                        )}
                        <InfoRow
                            id="industry-risk"
                            name={formatMessage({ defaultMessage: "Industry risk", description: "Industry risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={<IndustryRisk nace={supplier.nace ?? ""} />}
                        />
                    </Grid>
                    <Divider />
                    {/* Custom fields */}
                    <Typography variant="textMd" fontWeight={500}>
                        {mode === "configure" ? (
                            <FormattedMessage
                                defaultMessage="Additional fields"
                                description="Additional fields info header"
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Other data" description="Other data info header" />
                        )}
                    </Typography>
                    <Grid container rowSpacing={2}>
                        {customFields
                            .sort((a, b) => {
                                const aInOrdered = orderedFields.some((f) => f.fieldId === a.fieldId);
                                const bInOrdered = orderedFields.some((f) => f.fieldId === b.fieldId);

                                if (aInOrdered && !bInOrdered) return -1;
                                if (!aInOrdered && bInOrdered) return 1;

                                // If both are ordered, sort by their order
                                if (aInOrdered && bInOrdered) {
                                    const aOrder = orderedFields.find((f) => f.fieldId === a.fieldId)!.order;
                                    const bOrder = orderedFields.find((f) => f.fieldId === b.fieldId)!.order;
                                    return aOrder - bOrder;
                                }

                                // If neither is ordered, sort by field name
                                return a.name.localeCompare(b.name);
                            })
                            .map((field, index) => {
                                const visibleInCompactView = orderedFields.some((f) => f.fieldId === field.fieldId);
                                // need special handling of classification fields
                                if (field.fieldType !== SupplierCustomFieldType.Classification) {
                                    const value = JSON.parse(field.dataJson);
                                    return (
                                        <InfoRow
                                            id={field.fieldId}
                                            key={field.name}
                                            name={field.name}
                                            value={value}
                                            type={field.fieldType}
                                            onFieldChange={onFieldChange}
                                            onMoveUp={() => handleMoveField(field.fieldId, "up")}
                                            onMoveDown={() => handleMoveField(field.fieldId, "down")}
                                            isFirst={index === 0}
                                            isLast={index === orderedFields.length - 1}
                                            selectOptions={selectOptions[field.fieldId]}
                                            checked={visibleInCompactView}
                                            onToggle={handleFieldCheck}
                                            mode={mode}
                                        />
                                    );
                                }
                                const column = columns.find(
                                    (tc: AboutSupplierModal_ColumnsFragment) => tc.id === field.fieldId
                                );
                                // Parse a list of group ids
                                const groupIds = JSON.parse(field.dataJson);

                                const value =
                                    column && Array.isArray(groupIds)
                                        ? groupIds
                                              .map((groupId: string) => {
                                                  const group = (
                                                      column.typeOptions as ClassificationOptions
                                                  )?.groups?.find((g) => g.id === groupId);
                                                  return group?.value;
                                              })
                                              .filter((label) => label !== undefined)
                                        : "";

                                return (
                                    <InfoRow
                                        id={field.fieldId}
                                        key={field.fieldId}
                                        name={field.name}
                                        value={value}
                                        type={field.fieldType}
                                        checked={visibleInCompactView}
                                        onToggle={handleFieldCheck}
                                        mode={mode}
                                        onMoveUp={() => handleMoveField(field.fieldId, "up")}
                                        onMoveDown={() => handleMoveField(field.fieldId, "down")}
                                        isFirst={index === 0}
                                        isLast={index === orderedFields.length - 1}
                                    />
                                );
                            })}
                    </Grid>
                    {newUploadEnabled && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Documents" description="Documents info header" />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    id="code-of-conduct"
                                    name={formatMessage({
                                        defaultMessage: "Code of Conduct",
                                        description: "Code of Conduct",
                                    })}
                                    value=""
                                    component={
                                        <Chip
                                            variant="status"
                                            icon={<CircleSolid />}
                                            color={supplier.codeOfConducts.length > 0 ? "success" : "neutral"}
                                            label={
                                                supplier.codeOfConducts.length > 0
                                                    ? formatMessage({
                                                          defaultMessage: "Uploaded",
                                                          description: "Code of conduct uploaded status",
                                                      })
                                                    : formatMessage({
                                                          defaultMessage: "Missing",
                                                          description: "Code of conduct missing status",
                                                      })
                                            }
                                            size="small"
                                        />
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="certifications"
                                    name={formatMessage({
                                        defaultMessage: "Certifications",
                                        description: "Certifications",
                                    })}
                                    value=""
                                    component={<CertsInfo certs={supplier.certifications} />}
                                    type={SupplierCustomFieldType.Text}
                                />
                            </Grid>
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ boxShadow: "0px -1px 2px #0D10171F", paddingTop: 3 }}>
                {mode !== "display" ? (
                    <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
                        <NoPermissionTooltip hasPermission={isEditor}>
                            <Button
                                size="small"
                                color="secondary"
                                disabled={!isEditor || loading}
                                onClick={() => {
                                    track("Supplier Profile: Cancel Supplier Edit Clicked");
                                    setModalView("display");
                                }}
                            >
                                <FormattedMessage defaultMessage="Cancel" description="Cancel" />
                            </Button>
                        </NoPermissionTooltip>
                        <Button color="primary" size="small" disabled={!isEditor || loading} onClick={handleSave}>
                            {loading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <FormattedMessage defaultMessage="Save" description="Save button supplier info" />
                            )}
                        </Button>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
                        <Stack direction="row" spacing={2}>
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <Button
                                    size="small"
                                    color="secondary"
                                    disabled={!isEditor || loading}
                                    onClick={() => {
                                        track("Supplier Profile: Edit Supplier Info Clicked");
                                        setModalView("edit");
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Edit" description="Edit" />
                                </Button>
                            </NoPermissionTooltip>
                            <NoPermissionTooltip hasPermission={isAdmin}>
                                <Button
                                    size="small"
                                    color="secondary"
                                    disabled={!isAdmin || loading}
                                    onClick={() => {
                                        track("Supplier Profile: Configure Supplier Info Clicked");
                                        setModalView("configure");
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Configure" description="Edit" />
                                </Button>
                            </NoPermissionTooltip>
                        </Stack>
                        <Button color="primary" size="small" disabled={loading} onClick={handleClose}>
                            <FormattedMessage defaultMessage="Close" description="Close" />
                        </Button>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
};
