import { Box, Card, Chip, Paper, Popper, Tooltip as MUITooltip, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { DatePeriodSelection } from "@/components/Common/spendUtils";
import { DateInterval } from "@/gql/graphql";

import { formatSpend, getMonth, getShadeOfLight } from "./utils";

export type CategoryData = {
    period: string;
    [category: string]: number | string;
};

type CategoryChartProps = {
    period: DatePeriodSelection;
    currency?: string;
    categoryData: CategoryData[];
    categories: string[];
    hideAxisValues?: boolean;
};

export const CategoryChart: React.FC<CategoryChartProps> = ({ period, currency, categories, categoryData }) => {
    const theme = useTheme();

    const [isChartHovered, setIsChartHovered] = useState(false);
    const handleMouseOver = () => setIsChartHovered(true);
    const handleMouseOut = () => setIsChartHovered(false);

    return (
        <Box sx={{ position: "relative", zIndex: 2 }}>
            <Stack onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} sx={{ overflow: "visible" }}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={categoryData}>
                        <CartesianGrid strokeDasharray="3" vertical={false} />
                        <XAxis
                            dataKey="period"
                            axisLine={false}
                            tickLine={false}
                            tick={{
                                fill: theme.palette.grey[600],
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 14,
                            }}
                        />
                        <YAxis
                            axisLine={{ stroke: "transparent" }}
                            tickLine={false}
                            tickFormatter={(value) => formatSpend(value, true)}
                            tick={{
                                fill: theme.palette.grey[600],
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 14,
                            }}
                        />

                        <Tooltip
                            wrapperStyle={{ zIndex: 3000 }}
                            content={({ payload }) => (
                                <Card
                                    sx={{
                                        minWidth: 150,
                                        position: "relative",
                                        zIndex: 3000,
                                        boxShadow: theme.shadows[4],
                                    }}
                                >
                                    <Stack margin={1}>
                                        <Typography variant="textSm" marginLeft={0.5}>
                                            {period.interval == DateInterval.Month
                                                ? getMonth(payload?.[0]?.payload.period, "long")
                                                : payload?.[0]?.payload.period}
                                        </Typography>
                                        {payload
                                            ?.filter((p) => p.value != 0)
                                            .reverse()
                                            .map((p) => {
                                                return (
                                                    <Stack key={p.name} direction="row" spacing={1} marginY={0.5}>
                                                        <Box
                                                            sx={{
                                                                top: 16,
                                                                bottom: 16,
                                                                left: 8,
                                                                width: 8,
                                                                borderRadius: 4,
                                                                backgroundColor: p.color,
                                                            }}
                                                        />
                                                        <Stack>
                                                            <Typography variant="textXs">{p.name}</Typography>
                                                            <Typography variant="textMd">
                                                                {formatSpend(Number(p.value), true, currency)}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                );
                                            })}
                                    </Stack>
                                </Card>
                            )}
                            cursor={{ fill: "transparent" }}
                        />
                        <Legend
                            wrapperStyle={{ display: "flex", justifyContent: "center", width: "100%" }}
                            content={<CustomLegend payload={categories} />}
                        />
                        {categories.map((category, i) => {
                            return (
                                <Bar
                                    key={i}
                                    dataKey={category}
                                    stackId="period"
                                    fill={getShadeOfLight(i, categories.length, theme)}
                                    opacity={isChartHovered ? 0.6 : 1}
                                    activeBar={<Rectangle opacity={1} />}
                                />
                            );
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </Stack>
        </Box>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomLegend = ({ payload }: { payload: any[] }) => {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);
    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
    const handleMouseLeave = () => setAnchorEl(null);

    return (
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
            {payload.slice(0, 4).map((entry, index) => (
                <Stack key={index} direction="row">
                    <Stack direction="row" alignItems="center" spacing={0.5} width="100%">
                        <Box
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: entry.color,
                            }}
                        />
                        <MUITooltip title={<Typography variant="textXs">{entry.value}</Typography>}>
                            <Typography
                                variant="textSm"
                                sx={{
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                }}
                            >
                                {entry.value}
                            </Typography>
                        </MUITooltip>
                    </Stack>
                </Stack>
            ))}
            {payload.slice(4).length > 0 && (
                <>
                    <Stack onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <Chip label={`+${payload.slice(4).length}`} size="small" color="primary" />
                    </Stack>
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                        {({ TransitionProps }) => (
                            <Paper sx={{ p: 1 }} {...TransitionProps}>
                                {payload.slice(4).map((entry, index) => (
                                    <Stack key={`item-${index}`} direction="row" alignItems="center">
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 12,
                                                height: 12,
                                                backgroundColor: entry.color,
                                                display: "inline-block",
                                                borderRadius: "50%",
                                                mr: 1,
                                            }}
                                        />
                                        <Typography variant="textSm">{entry.value}</Typography>
                                    </Stack>
                                ))}
                            </Paper>
                        )}
                    </Popper>
                </>
            )}
        </Stack>
    );
};
