import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsVertical } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql/gql";
import { ClassificationOptions, SupplierCustomFieldType, SupplierInfo_SupplierFragment } from "@/gql/graphql";
import { useGeneralPermission, useSupplierPermission } from "@/lib/permissions";
import { track } from "@/lib/track";

import { IgniteEstimateChip, ManualStatusChip } from "../../../../Common/StatusChip";

import { AboutSupplierModal } from "./AboutSupplierModal";
import { CertsInfo } from "./CertsInfo";
import { CocInfoChip } from "./CocInfoChip";
import { GeoRisk } from "./RiskFields/GeoRisk";
import { IndustryRisk } from "./RiskFields/IndustryRisk";
import { ProductionCountries } from "./RiskFields/ProductionCountries";
import { InfoRow, isValidDate } from "./utils";

graphql(`
    fragment SupplierInfo_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        hasCodeOfConduct
        socialRiskScore {
            value
        }
        productionCountries
        onboarding {
            status
            approverId
            evaluatedAt
        }
        risk {
            social
            industry
            geography
        }
        ...AboutSupplierModal_Supplier
        codeOfConducts {
            filename
            expiresAt
            uploadedAt
            originalFilename
            uploadedBy
        }
        certifications {
            filename
            expiresAt
            uploadedAt
            documentType
            uploadedBy
            originalFilename
        }
    }

    fragment SupplierInfo_RoleUser on RoleUser {
        id
        firstName
        lastName
    }
`);

const getApproverUserQuery = graphql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...SupplierInfo_RoleUser
            }
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query getSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                type
                ...AboutSupplierModal_Columns
            }
        }
    }
`);

interface SupplierInfoProps {
    supplier: SupplierInfo_SupplierFragment;
    profileConfig: {
        displayOnboarding: boolean;
        displaySocialRisk: boolean;
        displayedCustomFields: string[];
    };
}

export const SupplierInfo: React.FC<SupplierInfoProps> = ({ supplier, profileConfig }) => {
    const isEditor = useSupplierPermission({ object: "general", relation: "write" });
    // Privileged write in general namespaces defines an admin
    const isAdmin = useGeneralPermission({ object: "privileged", relation: "write" });
    const [modalView, setModalView] = useState<"edit" | "configure" | "display" | null>(null);
    const { formatMessage } = useIntl();
    const [approver, setApprover] = useState("");
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);
    const newSocialRisk = useFeatureToggle("new-social-risk", false);
    // https://app.launchdarkly.com/projects/default/flags/configure-supplier-profile-enabled/targeting?env=production&env=test&selected-env=production
    const configureEnabled = useFeatureToggle("configure-supplier-profile-enabled", false);
    const newUploadEnabled = useFeatureToggle("new-upload", false);
    const { data: columnsData } = useQuery(getSupplierTableColumnsQuery);
    const supplierTableColumns = columnsData?.getSupplierTableMeta.columns ?? [];

    useQuery(getApproverUserQuery, {
        variables: {
            input: {
                userIds: [supplier.onboarding?.approverId ?? ""],
            },
        },
        skip: !supplier.onboarding?.approverId || supplier.onboarding?.approverId === "",
        onCompleted: (data) => {
            if (data.getUsers.result.length !== 1) return;
            setApprover(`${data.getUsers.result[0].firstName} ${data.getUsers.result[0].lastName}`);
        },
    });

    const onboardingDate = supplier.onboarding?.evaluatedAt ?? "";
    const isValidOnboardingDate = isValidDate(onboardingDate);

    /**
     * Classification fields need additional data fetching to get the group labels.
     */
    const customFields = supplier.customFields
        .map((field) => {
            let value = JSON.parse(field.dataJson);
            if (field.fieldType === SupplierCustomFieldType.Classification) {
                const column = supplierTableColumns.find((tc) => tc.id === field.fieldId);
                if (column && Array.isArray(value)) {
                    value = value
                        .map((groupId: string) => {
                            const group = (column.typeOptions as ClassificationOptions)?.groups?.find(
                                (g) => g.id === groupId
                            );
                            return group?.value;
                        })
                        .filter((label) => label !== undefined);
                }
            }
            const order = profileConfig.displayedCustomFields.indexOf(field.fieldId);
            return {
                id: field.fieldId,
                name: field.name,
                value,
                type: field.fieldType,
                order,
                visibleInCompactView: order !== -1,
            };
        })
        .sort((a, b) => a.order - b.order);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="About" description="supplier information header" />
                    </Typography>
                }
                action={
                    <IconButton
                        type="button"
                        color="secondary"
                        size="small"
                        onClick={(event) => {
                            track("Supplier Profile: Edit Supplier Info Opened");
                            setMenuAnchorEl(event.currentTarget);
                        }}
                    >
                        <DotsVertical />
                    </IconButton>
                }
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClick={() => setMenuAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    message={
                        <FormattedMessage defaultMessage="Your permissions do not allow editing this supplier profile" />
                    }
                >
                    <MenuItem onClick={() => setModalView("edit")} disabled={!isEditor}>
                        <FormattedMessage defaultMessage="Edit" description="Edit button" />
                    </MenuItem>
                </NoPermissionTooltip>
                {configureEnabled && (
                    <NoPermissionTooltip
                        hasPermission={isAdmin}
                        message={
                            <FormattedMessage
                                defaultMessage="Only Ignite admins can configure supplier profiles"
                                description="Tooltip message for configuring supplier profiles"
                            />
                        }
                    >
                        <MenuItem onClick={() => setModalView("configure")} disabled={!isAdmin}>
                            <FormattedMessage defaultMessage="Configure view" description="Menu item for configuring" />
                        </MenuItem>
                    </NoPermissionTooltip>
                )}
            </Menu>
            <CardContent sx={{ paddingTop: 0 }}>
                <Stack spacing={2}>
                    <Grid container rowSpacing={2}>
                        <InfoRow
                            id="country"
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                        <InfoRow
                            id="industry"
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                        />
                        <InfoRow
                            id="vat-id"
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                    </Grid>
                    {supplier.onboarding && onboardingEnabled && profileConfig.displayOnboarding && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Onboarding" description="Onboarding info header" />
                            </Typography>
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 },
                                }}
                            >
                                <InfoRow
                                    id="onboarding-approver"
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={
                                        approver
                                            ? approver
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approver placeholder",
                                              })
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="onboarding-approved-at"
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={
                                        isValidOnboardingDate
                                            ? onboardingDate
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approved at placeholder",
                                              })
                                    }
                                    type={
                                        isValidOnboardingDate
                                            ? SupplierCustomFieldType.Date
                                            : SupplierCustomFieldType.Text
                                    }
                                />
                                <InfoRow
                                    id="onboarding-status"
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                        </>
                    )}
                    {profileConfig.displaySocialRisk && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Social Risk" description="Social Risk info header" />
                            </Typography>
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 },
                                }}
                            >
                                {newSocialRisk ? (
                                    <InfoRow
                                        id="social-risk"
                                        name={formatMessage({
                                            defaultMessage: "Estimated Risk",
                                            description: "Social risk",
                                        })}
                                        value=""
                                        type={SupplierCustomFieldType.Risk}
                                        component={
                                            supplier.socialRiskScore?.value ? (
                                                <ManualStatusChip status={supplier.socialRiskScore.value} />
                                            ) : (
                                                <IgniteEstimateChip status={supplier.risk?.social} />
                                            )
                                        }
                                    />
                                ) : (
                                    <InfoRow
                                        id="social-risk"
                                        name={formatMessage({
                                            defaultMessage: "Social risk",
                                            description: "Social risk",
                                        })}
                                        value={supplier.risk?.social ?? ""}
                                        type={SupplierCustomFieldType.Risk}
                                    />
                                )}
                                <InfoRow
                                    id="geography-risk"
                                    name={formatMessage({
                                        defaultMessage: "Geography risk",
                                        description: "Geography risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={
                                        <GeoRisk
                                            country={
                                                supplier.productionCountries.length > 0 &&
                                                supplier.productionCountries[0] !== ""
                                                    ? supplier.productionCountries[0]
                                                    : supplier.country ?? ""
                                            }
                                        />
                                    }
                                />
                                {newSocialRisk && (
                                    <InfoRow
                                        id="production-countries"
                                        name={formatMessage({
                                            defaultMessage: "Production countries",
                                            description: "Production countries",
                                        })}
                                        value=""
                                        type={SupplierCustomFieldType.Text}
                                        component={
                                            <ProductionCountries countries={supplier.productionCountries ?? []} />
                                        }
                                    />
                                )}
                                <InfoRow
                                    id="industry-risk"
                                    name={formatMessage({
                                        defaultMessage: "Industry risk",
                                        description: "Industry risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={<IndustryRisk nace={supplier.nace ?? ""} />}
                                />
                            </Grid>
                        </>
                    )}
                    {profileConfig.displayedCustomFields.length > 0 && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Additional fields"
                                    description="Additional fields info header"
                                />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                {customFields
                                    .filter((field) => field.visibleInCompactView)
                                    .map((field) => (
                                        <InfoRow
                                            id={field.id}
                                            key={field.id}
                                            name={field.name}
                                            value={field.value}
                                            type={field.type}
                                        />
                                    ))}
                            </Grid>
                        </>
                    )}
                    {newUploadEnabled && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Documents" description="Documents info header" />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    id="code-of-conduct"
                                    name={formatMessage({
                                        defaultMessage: "Code of Conduct",
                                        description: "Code of Conduct",
                                    })}
                                    value=""
                                    component={<CocInfoChip codeOfConducts={supplier.codeOfConducts ?? []} />}
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="certifications"
                                    name={formatMessage({
                                        defaultMessage: "Certifications",
                                        description: "Certifications",
                                    })}
                                    value=""
                                    component={<CertsInfo certs={supplier.certifications ?? []} />}
                                    type={SupplierCustomFieldType.Text}
                                />
                            </Grid>
                        </>
                    )}
                </Stack>
            </CardContent>
            <CardActions>
                <Stack width="100%">
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            track("Supplier Profile: Show More Supplier Info Opened");
                            setModalView("display");
                        }}
                    >
                        <FormattedMessage defaultMessage="Show more" description="Show more button supplier info" />
                    </Button>
                </Stack>
            </CardActions>
            {modalView !== null && (
                <AboutSupplierModal
                    supplier={supplier}
                    approver={approver}
                    profileConfig={profileConfig}
                    mode={modalView}
                    setModalView={setModalView}
                    onboardingEnabled={onboardingEnabled}
                />
            )}
        </Card>
    );
};
