import { CircleSolid } from "@ignite-analytics/icons";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

interface ExpirationChipProps {
    expiryDate: string;
}

export const ExpirationChip = ({ expiryDate }: ExpirationChipProps) => {
    const expiryDateString = expiryDate ? dayjs(expiryDate).format("DD MMM YYYY") : null;
    const daysUntilExpiry = expiryDate ? dayjs(expiryDate).startOf("day").diff(dayjs().startOf("day"), "day") : null;
    const { formatMessage } = useIntl();

    if (!daysUntilExpiry) return null;
    return (
        <Chip
            label={
                daysUntilExpiry < 0
                    ? formatMessage({ defaultMessage: "Expired" })
                    : daysUntilExpiry && daysUntilExpiry < 61
                      ? formatMessage(
                            {
                                defaultMessage: "Expires in {days} {days, plural, one {day} other {days}}",
                            },
                            { days: daysUntilExpiry }
                        )
                      : `${formatMessage({ defaultMessage: "Expires on" })} ${expiryDateString}`
            }
            color={daysUntilExpiry < 61 ? "warning" : "neutral"}
            size="small"
            variant="status"
            icon={<CircleSolid />}
            clickable={false}
        />
    );
};
