import { useMutation } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import { Chip, LinearProgress, Paper, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";
import { useAlert } from "@/providers";

import { getShortDocumentTypeName } from "../UploadModal/types";

import { DocumentActions } from "./DocumentActions";
import { DocumentTypeSelector } from "./DocumentTypeSelector";
import { ExpirationChip } from "./ExpirationChip";

export type DocumentListFiles = DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment;

type Props = {
    files: DocumentListFiles[];
    isLoading: boolean;
    supplierId: string;
    isEditor: boolean;
};

const DocumentsList_UpdateSupplierFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateSupplierFileMetadataMutation($input: UpdateSupplierFileMetadataInput!) {
        updateSupplierFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

const DocumentsList_UpdateAssessmentsFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateAssessmentsFileMetadataMutation($input: UpdateAssessmentsFileMetadataInput!) {
        updateAssessmentsFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

graphql(`
    fragment DocumentsList_SupplierFile on SupplierFile {
        name
        createdAt
        metaJson
        size
        supplierId
        ...DocumentsActions_SupplierFile
    }

    fragment DocumentsList_AssessmentsFile on AssessmentsFile {
        name
        createdAt
        metaJson
        size
        referenceId
    }
`);

const isScanning = (file: DocumentListFiles) => {
    if (!file.metaJson) return false;
    const { av_scanned } = JSON.parse(file.metaJson);
    return av_scanned === "FALSE";
};

export const DocumentsList: React.FC<Props> = ({ files, supplierId, isEditor }) => {
    const { formatMessage, formatDate } = useIntl();
    const [updateSupplierFileMetadata] = useMutation(DocumentsList_UpdateSupplierFileMetadataMutation);
    const [updateAssessmentsFileMetadata] = useMutation(DocumentsList_UpdateAssessmentsFileMetadataMutation);
    const hasNewUploadToggle = useFeatureToggle("new-upload");
    const { alertUser } = useAlert();

    const handleSelectChange = (file: DocumentListFiles, event: SelectChangeEvent) => {
        const { value } = event.target;
        const errorMessage = formatMessage(
            {
                defaultMessage: "Failed to change document type for: {file}",
                description: "File metadata update fail message",
            },
            { file: file.name }
        );
        if (file && file.metaJson) {
            const updatedFile = {
                ...file,
                metaJson: JSON.stringify({
                    ...JSON.parse(file.metaJson),
                    doctype: value,
                }),
            };
            if (file.__typename === "AssessmentsFile") {
                updateAssessmentsFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            referenceId: file.referenceId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                });
            } else {
                updateSupplierFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            supplierId: file.supplierId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                });
            }
            track("Supplier Profile: Selected Document Type", { supplierId, documentType: value });
        }
    };

    const convertDocumentOrigin = (metaJson: string) => {
        const { origin } = JSON.parse(metaJson);
        switch (origin) {
            case "supplier-page":
                return formatMessage({
                    defaultMessage: "Upload",
                    description: "Documents uploaded from supplier-page",
                });
            case "assessment":
                return formatMessage({
                    defaultMessage: "Assessments",
                    description: "Documents uploaded from assessment answer",
                });
            default:
                return formatMessage({ defaultMessage: "Unknown", description: "Unknown document upload origin" });
        }
    };

    return files?.map((file) => {
        const { originalFileName } = getNameAndContentType(file);
        const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
        const docType = metaJson.doctype;
        const expiryDate = metaJson.expirydate;
        return isScanning(file) ? (
            <Paper sx={{ padding: 2, width: "100%" }}>
                <Stack spacing={1}>
                    <LinearProgress variant="indeterminate" />
                    <Typography
                        variant="textSm"
                        fontWeight={500}
                        sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                    >
                        <FormattedMessage
                            defaultMessage="Uploading {file}..."
                            values={{ file: decodeURIComponent(originalFileName) }}
                        />
                    </Typography>
                </Stack>
            </Paper>
        ) : (
            <Paper key={`${file.name}+${file.metaJson}`} sx={{ padding: 2 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack spacing={1} pr={2}>
                        <Typography
                            variant="textSm"
                            fontWeight={500}
                            sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                        >
                            {decodeURIComponent(originalFileName)}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {hasNewUploadToggle && getShortDocumentTypeName(docType) !== "" && (
                                <Chip
                                    label={getShortDocumentTypeName(docType)}
                                    color="primary"
                                    size="small"
                                    clickable={false}
                                />
                            )}
                            {file.metaJson && !hasNewUploadToggle && (
                                <NoPermissionTooltip hasPermission={isEditor}>
                                    <DocumentTypeSelector
                                        file={file}
                                        onChange={handleSelectChange}
                                        isEditor={isEditor}
                                    />
                                </NoPermissionTooltip>
                            )}
                            <Typography variant="textXs">
                                {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                            </Typography>
                            {file.metaJson && (
                                <Typography variant="textXs">{convertDocumentOrigin(file.metaJson)}</Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {hasNewUploadToggle && <ExpirationChip expiryDate={expiryDate} />}
                        <DocumentActions isEditor={isEditor} file={file} supplierId={supplierId} />
                    </Stack>
                </Stack>
            </Paper>
        );
    });
};
