import { useQuery } from "@apollo/client";
import { Stack, Chip, Popper, Paper, Typography, Box } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Certification } from "@/gql/graphql";

import { ExpirationChip } from "../../MainColumn/Documents/DocumentsList/ExpirationChip";
import { getShortDocumentTypeName } from "../../MainColumn/Documents/UploadModal/types";

const getUsersDocument = graphql(`
    query SupplierInfo_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...Activities_User
            }
        }
    }
`);

export const CertsInfo = ({ certs }: { certs: Certification[] }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [singleOpen, setSingleOpen] = useState(false);
    const [multipleOpen, setMultipleOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setSingleOpen(true);
    };
    const handleMouseLeave = () => {
        setSingleOpen(false);
    };

    const handlePlusOneMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMultipleOpen(true);
    };
    const handlePlusOneMouseLeave = () => {
        setMultipleOpen(false);
    };

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    if (!certs?.length) {
        return null;
    }

    const usersResult = usersData?.getUsers?.result;

    return (
        <Stack direction="row" spacing={1}>
            <Chip
                key={certs[0].documentType}
                label={getShortDocumentTypeName(certs[0].documentType)}
                color="primary"
                size="small"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <Popper open={singleOpen} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                <Paper sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={0.5}>
                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                    <FormattedMessage defaultMessage="Uploaded:" />
                                </Typography>
                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                    {new Date(certs[0].uploadedAt).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                    })}
                                    {certs[0].uploadedBy &&
                                        usersResult?.find((user) => user.id === certs[0].uploadedBy)?.fullName &&
                                        `, ${usersResult.find((user) => user.id === certs[0].uploadedBy)?.fullName}`}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={5} justifyContent="space-between">
                                {certs[0].originalFilename && (
                                    <Stack spacing={0.5}>
                                        <Typography variant="textMd" fontWeight={500}>
                                            {decodeURIComponent(certs[0].originalFilename)}
                                        </Typography>
                                    </Stack>
                                )}
                                <Box display="inline-block">
                                    <Chip
                                        label={getShortDocumentTypeName(certs[0].documentType)}
                                        color="primary"
                                        size="small"
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Popper>
            {certs.length > 1 && (
                <>
                    <Chip
                        label={`+${certs.length - 1}`}
                        color="primary"
                        size="small"
                        onMouseEnter={handlePlusOneMouseEnter}
                        onMouseLeave={handlePlusOneMouseLeave}
                    />
                    <Popper open={multipleOpen} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                        <Paper sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                {certs.slice(1).map((certification: Certification) => (
                                    <Stack
                                        spacing={1}
                                        key={certification.filename}
                                        sx={{
                                            "&:not(:last-of-type)": {
                                                borderBottom: "1px solid",
                                                borderColor: "divider",
                                                pb: 2,
                                            },
                                        }}
                                    >
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                <FormattedMessage defaultMessage="Uploaded:" />
                                            </Typography>
                                            <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                {new Date(certification.uploadedAt).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "short",
                                                    year: "numeric",
                                                })}
                                                {certification.uploadedBy &&
                                                    usersResult?.find((user) => user.id === certification.uploadedBy)
                                                        ?.fullName &&
                                                    `, ${usersResult.find((user) => user.id === certification.uploadedBy)?.fullName}`}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={5} justifyContent="space-between">
                                            {certification.originalFilename && (
                                                <Stack spacing={0.5}>
                                                    <Typography variant="textMd" fontWeight={500}>
                                                        {decodeURIComponent(certification.originalFilename)}
                                                    </Typography>
                                                </Stack>
                                            )}
                                            <Box display="inline-block">
                                                <Chip
                                                    key={certification.documentType}
                                                    label={getShortDocumentTypeName(certification.documentType)}
                                                    color="primary"
                                                    size="small"
                                                />
                                            </Box>
                                        </Stack>
                                        {certification.expiresAt && (
                                            <Stack spacing={0.5} display="inline-block">
                                                <ExpirationChip expiryDate={certification.expiresAt} />
                                            </Stack>
                                        )}
                                    </Stack>
                                ))}
                            </Stack>
                        </Paper>
                    </Popper>
                </>
            )}
        </Stack>
    );
};
