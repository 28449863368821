import { Box, Card, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { GraphEmptyState } from "@/assets/GraphEmptyState";
import { renderCustomLegend } from "@/components/Common/utils";
import { graphql } from "@/gql";
import { EmissionsDevelopmentChart_TotalEmissionsFragment } from "@/gql/graphql";

graphql(`
    fragment EmissionsDevelopmentChart_TotalEmissions on TotalEmissions {
        year
        activityBasedEmissions
        spendBasedEmissions
        totalEmissions {
            value
        }
    }
`);

type EmissionsDevelopmentChartProps = {
    emissions: EmissionsDevelopmentChart_TotalEmissionsFragment[] | undefined | null;
    loading?: boolean;
    isBarHovered?: boolean;
};

export const EmissionsDevelopmentChart: React.FC<EmissionsDevelopmentChartProps> = ({
    emissions,
    loading,
    isBarHovered,
}) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    if (loading) {
        return <Skeleton variant="rectangular" width="100%" height={300} />;
    }

    if (!emissions || emissions.length === 0) {
        return (
            <Stack alignItems="center" justifyContent="center" height="376px" width="100%" spacing={2}>
                <GraphEmptyState />

                <Typography fontWeight={400} variant="textSm">
                    {formatMessage({ defaultMessage: "No emissions data available" })}
                </Typography>
            </Stack>
        );
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={emissions.map((d) => ({
                    year: d.year,
                    spendBased: d.spendBasedEmissions,
                    activityBased: d.activityBasedEmissions,
                    value: d.totalEmissions.value,
                }))}
            >
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis
                    dataKey="year"
                    axisLine={false}
                    tickLine={false}
                    tick={{
                        fill: theme.palette.grey[600],
                        fontFamily: theme.typography.fontFamily,
                        fontSize: 14,
                    }}
                />
                <YAxis
                    tickFormatter={(value) => `${value.toLocaleString()}`}
                    axisLine={false}
                    tickLine={false}
                    tick={{
                        fill: theme.palette.grey[600],
                        fontFamily: theme.typography.fontFamily,
                        fontSize: 14,
                    }}
                />
                <Legend
                    content={renderCustomLegend([
                        {
                            title: formatMessage({ defaultMessage: "Spend based" }),
                            color: theme.palette.tokens?.chart.chartEmission700,
                        },
                        {
                            title: formatMessage({ defaultMessage: "Activity based" }),
                            color: theme.palette.tokens?.chart.chartEmission400,
                        },
                    ])}
                />
                <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={({ payload }) => {
                        if (!payload || payload.length === 0) return null;
                        const data: { year: number; value: number; spendBased: number; activityBased: number } =
                            payload[0]?.payload;
                        const spendString = `${data?.spendBased?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} tCO₂e`;
                        const activityString = `${data?.activityBased?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} tCO₂e`;
                        const totalString = `${data?.value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} tCO₂e`;

                        return (
                            <Card sx={{ minWidth: 150, position: "relative" }}>
                                <Stack margin={1} spacing={1}>
                                    <Typography variant="textSm" fontWeight={500} marginLeft={0.5}>
                                        {data?.year?.toString()}
                                    </Typography>

                                    <Stack direction="row" spacing={1}>
                                        <Box
                                            sx={{
                                                top: 16,
                                                bottom: 16,
                                                left: 8,
                                                width: 8,
                                                borderRadius: 4,
                                                backgroundColor: theme.palette.tokens?.chart.chartEmission400,
                                            }}
                                        />
                                        <Stack>
                                            <Typography variant="textXs">
                                                <FormattedMessage defaultMessage="Activity based" />
                                            </Typography>
                                            <Typography variant="textMd">{activityString}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Box
                                            sx={{
                                                top: 16,
                                                bottom: 16,
                                                left: 8,
                                                width: 8,
                                                borderRadius: 4,
                                                backgroundColor: theme.palette.tokens?.chart.chartEmission700,
                                            }}
                                        />
                                        <Stack>
                                            <Typography variant="textXs">
                                                <FormattedMessage defaultMessage="Spend based" />
                                            </Typography>
                                            <Typography variant="textMd">{spendString}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Box
                                            sx={{
                                                top: 16,
                                                bottom: 16,
                                                left: 8,
                                                width: 8,
                                                borderRadius: 4,
                                                backgroundColor: theme.palette.primary.main,
                                            }}
                                        />
                                        <Stack>
                                            <Typography variant="textXs">
                                                <FormattedMessage defaultMessage="Total" />
                                            </Typography>
                                            <Typography variant="textMd">{totalString}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Card>
                        );
                    }}
                />
                <Bar
                    stackId="a"
                    dataKey="spendBased"
                    fill={theme.palette.tokens?.chart.chartEmission700}
                    opacity={isBarHovered ? 0.6 : 1}
                    activeBar={<Rectangle opacity={1} fill={theme.palette.tokens?.chart.chartEmission700} />}
                />
                <Bar
                    stackId="a"
                    dataKey="activityBased"
                    fill={theme.palette.tokens?.chart.chartEmission400}
                    opacity={isBarHovered ? 0.6 : 1}
                    activeBar={<Rectangle opacity={1} fill={theme.palette.tokens?.chart.chartEmission400} />}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};
