import { Card, CardContent, Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";

import { PercentageChip } from "@/components/Common/ValueChip";

import { getColorFactory } from "./helpers";

type RiskChartProps = {
    data: {
        risk: {
            name: string;
            value: number;
        }[];
        liquidity: number;
        solvency: number;
        roce: number;
    };
    logo: React.ReactNode;
};

export const RiskChart: React.FC<RiskChartProps> = ({ data, logo }) => {
    const theme = useTheme();
    const radialData = [{ value: data.risk[0]?.value ?? 0, fill: theme.palette.secondary.light }];
    const { formatMessage } = useIntl();

    return (
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Risk" />
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <RadialBarChart
                            innerRadius="60%"
                            outerRadius="90%"
                            startAngle={90}
                            endAngle={-270}
                            data={radialData}
                        >
                            <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                            <RadialBar
                                background
                                dataKey="value"
                                angleAxisId={0}
                                isAnimationActive={false}
                                label={({ value }) => (
                                    <foreignObject x="25%" y="41%" width="50%" height="50%">
                                        <Stack style={{ textAlign: "center" }}>
                                            <Typography variant="textXs">
                                                <FormattedMessage defaultMessage="Overall risk score" />
                                            </Typography>
                                            <Typography fontWeight={700} fontSize={32} marginTop={-1}>
                                                {value ? `${value}/100` : "N/A"}
                                            </Typography>
                                        </Stack>
                                    </foreignObject>
                                )}
                            />
                        </RadialBarChart>
                    </ResponsiveContainer>
                    <Stack direction="row" spacing={2} justifyContent="center" paddingBottom={5}>
                        <PercentageChip
                            value={data.liquidity}
                            label={formatMessage({ defaultMessage: "Liquidity" })}
                            color={getColorFactory(0.5, 1, data.liquidity, false)}
                            tooltipTitle={formatMessage({ defaultMessage: "Liquidity" })}
                            tooltipText={formatMessage({
                                defaultMessage:
                                    "The current ratio measures the ability to pay short-term (and long-term obligations). The liquidity ratio considers the current assets of a company (both liquid and illiquid) relative to that company's current liabilities.",
                            })}
                        />
                        <PercentageChip
                            value={data.solvency}
                            label={formatMessage({ defaultMessage: "Solvency" })}
                            color={getColorFactory(0.09, 0.4, data.solvency, false)}
                            tooltipTitle={formatMessage({ defaultMessage: "Solvency" })}
                            tooltipText={formatMessage({
                                defaultMessage:
                                    "The equity ratio is a solvency ratio indicating the relative proportion of equity used to finance a company's assets, and a good indicator of the level of leverage used by a company. The ratio indicates a company's ability to withstand losses and to fulfil financial commitments in the long run.",
                            })}
                        />
                        <PercentageChip
                            value={data.roce}
                            label={formatMessage({ defaultMessage: "ROCE" })}
                            color={getColorFactory(-0.5, 0.15, data.roce, false)}
                            tooltipTitle={formatMessage({ defaultMessage: "ROCE" })}
                            tooltipText={formatMessage({
                                defaultMessage:
                                    "Return on capital employed (ROCE) is a profitability ratio that measures how efficiently the supplier is using its capital. ROCE is calculated by dividing operating profit (EBIT) to capital employed for the latest available financial data. The rate is typically compared to the required rate of return, often expressed by the weighted average cost of capital (WACC).",
                            })}
                        />
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="The risk analysis rates your suppliers through a risk rating based on three different metrics: liquidity (current ratio), return on capital employed (ROCE), and Solvency (equity ratio)." />
                    </Typography>
                    <Stack spacing={2} alignItems="flex-end" marginTop={4}>
                        {logo}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};
