import { Category, Company } from "@ignite-analytics/icons";
import { Chip, ChipProps, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { ProcurementEntityType } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

export type CategoryEntity = {
    id: string;
    type: ProcurementEntityType;
    label: string;
};
interface CategoryBarProps {
    entities: CategoryEntity[];
}

interface MoreChipProps extends ChipProps {
    entities: CategoryEntity[];
}

export const CategoryBar: React.FC<CategoryBarProps> = ({ entities }) => {
    const categories = entities.filter((entity) => entity.type === ProcurementEntityType.Category);
    const departments = entities.filter((entity) => entity.type === ProcurementEntityType.Department);
    return (
        <Stack direction="row" spacing={1}>
            {categories.length > 0 && <EntityChip entity={categories[0]} />}
            {categories.length > 1 && <MoreChip entities={categories.slice(1)} color="category" />}
            {departments.length > 0 && <EntityChip entity={departments[0]} />}
            {departments.length > 1 && <MoreChip entities={departments.slice(1)} color="businessUnit" />}
        </Stack>
    );
};

interface EntityChipProps {
    entity: CategoryEntity;
}

const EntityChip: React.FC<EntityChipProps> = ({ entity }) => {
    const navigate = useOutgoingNavigate();
    return (
        <Chip
            icon={entity.type == ProcurementEntityType.Category ? <Category /> : <Company />}
            label={entity.label}
            size="small"
            color={entity.type == ProcurementEntityType.Category ? "category" : "businessUnit"}
            onClick={() => {
                track("Supplier Profile: Navigated Header Category", {
                    type: entity.type == ProcurementEntityType.Category ? "Category" : "Department",
                });
                navigate(
                    `/spend/${entity.type == ProcurementEntityType.Category ? "categories" : "business-units"}/${entity.id}/spend?perspective=SUPPLIER`
                );
            }}
            clickable
        />
    );
};

const MoreChip: React.FC<MoreChipProps> = ({ entities, ...chipProps }) => {
    const navigate = useOutgoingNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        track("Supplier Profile: Clicked Header More Categories");
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Chip
                label={`+${entities.length}`}
                clickable
                color="primary"
                size="small"
                onClick={handleClick}
                {...chipProps}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{ marginTop: 1 }}
            >
                {entities.map((entity, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            track("Supplier Profile: Navigated Header Category", {
                                type: entity.type == ProcurementEntityType.Category ? "Category" : "Department",
                            });
                            navigate(
                                `/spend/${entity.type == ProcurementEntityType.Category ? "categories" : "business-units"}/${entity.id}/spend?perspective=SUPPLIER`
                            );
                        }}
                        sx={{
                            "&:hover": {
                                cursor: entity.type == ProcurementEntityType.Category ? "pointer" : "default",
                            },
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            {entity.type == ProcurementEntityType.Category ? <Category /> : <Company />}
                            <Typography variant="textSm">{entity.label}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
